<template>
 <div v-loading.fullscreen="isLoading"></div>
</template>

<script>
import { logoutUser } from '../shared/services/auth'
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'AuthLogout',
  created() {
    this.logout()
  },
  data() {
    return {
      isLoading: true
    }
  },
  methods: {
    ...mapMutations(['authState/REMOVE_USER_DATA']),
    async logout() {
      this.isLoading = true
      await logoutUser()
      await this['authState/REMOVE_USER_DATA']()
      this.isLoading = false
      await this.$router.push({ name: 'home' })
    }
  }
}
</script>
